
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 384,
            //             phoneElementId: 392,
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人',
            //                     sort: 1
            //                 }],
            //             type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 385,
            //             phoneElementId: 393,
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: '能够实现设备区域全天候全覆盖巡视，具备表计识别、跑滴漏、体液位、气体监测、红外测温、异常监测等多重功能，可以有效解决石油化工行业日常巡检中常见的痛点难点问题，助力企业提升安全作业水平，为厂区生产安全保驾护航。',
            //                     sort: 2
            //                 },],
            //             type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 386,
            //             phoneElementId: 394,
            //             descList: [
            //                 {
            //                     descName: '巡检数据管理',
            //                     sort: 1
            //                 }, {
            //                     descName: '可以通过七腾机器人巡检平台对机器人运行状态、巡检信息、巡检项结果、巡检地图、环境信息、台账数据、巡检视频、预警信息等内容进行查看、设置和管理，全面实现信息数据化，提高提升企业数字化运维能力。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 387,
            //             phoneElementId: 395,
            //             descList: [
            //                 {
            //                     descName: '表计识别读数',
            //                     sort: 1
            //                 }, {
            //                     descName: '通过AI视觉技术读取各类仪器仪表数据，采集到的数据超过阈值则产生预警。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId: 388,
            //             phoneElementId: 396,
            //             descList: [
            //                 {
            //                     descName: '跑冒滴漏监测',
            //                     sort: 1
            //                 }, {
            //                     descName: '通过AI视觉识别技术等手段进行跑冒滴漏检测，能够显示泄漏位置的坐标并预警。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 389,
            //             phoneElementId: 397,
            //             descList: [
            //                 {
            //                     descName: '设备红外测温',
            //                     sort: 1
            //                 }, {
            //                     descName: '搭载红外热成像仪，能够对设备表面温度进行采集，对设备温度异常进行预警。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcElementId: 390,
            //             phoneElementId: 398,
            //             descList: [
            //                 {
            //                     descName: '环境气体监测',
            //                     sort: 1
            //                 }, {
            //                     descName: '可对氧气、一氧化碳、可燃性气体、硫化氢等易燃易爆、有毒有害气体进行实时监测。',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcElementId: '',
            //             phoneElementId: 400,
            //             descList: [
            //                 {
            //                     descName: '产品型号',
            //                     sort: 1
            //                 }, {
            //                     descName: '外形尺寸',
            //                     sort: 2
            //                 }, {
            //                     descName: '整备质量',
            //                     sort: 3
            //                 }, {
            //                     descName: '防爆等级',
            //                     sort: 4
            //                 }, {
            //                     descName: '防护等级',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '行走速度',
            //                     sort: 6
            //                 }, {
            //                     descName: '驱动方式',
            //                     sort: 7
            //                 }, {
            //                     descName: '爬坡能力',
            //                     sort: 8
            //                 }, {
            //                     descName: '越障能力',
            //                     sort: 9
            //                 },
            //                 {
            //                     descName: '涉水深度',
            //                     sort: 10
            //                 }, {
            //                     descName: '续航时间',
            //                     sort: 11
            //                 }, {
            //                     descName: '整机配置',
            //                     sort: 12
            //                 }],
            //             type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcElementId: '',
            //             phoneElementId: '',
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人入门版',
            //                     sort: 1
            //                 }, {
            //                     descName: '≤1420mmX820mmX1040(长X宽X高)',
            //                     sort: 2
            //                 }, {
            //                     descName: '≤350kg',
            //                     sort: 3
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 4
            //                 }, {
            //                     descName: 'IP65',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '0-0.8m/s,速度可调',
            //                     sort: 6
            //                 }, {
            //                     descName: '四轮两驱',
            //                     sort: 7
            //                 }, {
            //                     descName: '<15°',
            //                     sort: 8
            //                 }, {
            //                     descName: '100mm',
            //                     sort: 9
            //                 },
            //                 {
            //                     descName: '150mm',
            //                     sort: 10
            //                 }, {
            //                     descName: '不小于3h',
            //                     sort: 11
            //                 }, {
            //                     descName: '机器人本体、遥控器、标配算法2类、有线充电器',
            //                     sort: 12
            //                 }],
            //             type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcElementId: '',
            //             phoneElementId: '',
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人基础版',
            //                     sort: 1
            //                 }, {
            //                     descName: '≤1420mmX820mmX1055(长X宽X高)',
            //                     sort: 2
            //                 }, {
            //                     descName: '≤350kg',
            //                     sort: 3
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 4
            //                 }, {
            //                     descName: 'IP65',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '0-0.8m/s,速度可调',
            //                     sort: 6
            //                 }, {
            //                     descName: '四轮两驱',
            //                     sort: 7
            //                 }, {
            //                     descName: '20°',
            //                     sort: 8
            //                 }, {
            //                     descName: '100mm',
            //                     sort: 9
            //                 },
            //                 {
            //                     descName: '150mm',
            //                     sort: 10
            //                 }, {
            //                     descName: '不小于4h',
            //                     sort: 11
            //                 }, {
            //                     descName: '机器人本体、遥控器、标配算法4类、防爆充电桩',
            //                     sort: 12
            //                 }],
            //             type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcElementId: '',
            //             phoneElementId: '',
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人超值版',
            //                     sort: 1
            //                 }, {
            //                     descName: '≤1420mmX820mmX1055(长X宽X高)',
            //                     sort: 2
            //                 }, {
            //                     descName: '≤400kg',
            //                     sort: 3
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 4
            //                 }, {
            //                     descName: 'IP66',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '0-1m/s,速度可调',
            //                     sort: 6
            //                 }, {
            //                     descName: '四轮四驱',
            //                     sort: 7
            //                 }, {
            //                     descName: '20°',
            //                     sort: 8
            //                 }, {
            //                     descName: '120mm',
            //                     sort: 9
            //                 },
            //                 {
            //                     descName: '150mm',
            //                     sort: 10
            //                 }, {
            //                     descName: '不小于5h',
            //                     sort: 11
            //                 }, {
            //                     descName: '机器人本体、遥控器、标配算法5类、防爆充电桩',
            //                     sort: 12
            //                 }],
            //             type: 'image',
            //             sort: 11
            //         },
            //         {
            //             pcElementId: 391,
            //             phoneElementId: 399,
            //             descList: [
            //                 {
            //                     descName: '防爆化工轮式巡检机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: '以机代人，轻享安全新体验',
            //                     sort: 2
            //                 },],
            //             type: 'image',
            //             sort: 12
            //         }
            //     ],
            elmentList: [],
        }

    },
    created() {
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: '81',//页面id
        //         pageName:'/chemicalWheelLightly',
        //         path: '/chemicalWheelLightly',
        //         remark: '',
        //     }
        // );
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
            this.$router.push('/pdfView')
        },

    }
}