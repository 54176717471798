<template>
  <div class="product electricWheel" v-if="elmentList.length">
    <div class="banner_video" :id="'elm' + elmentList[0].id">
      <img :src="elmentList[0].pcImg" v-if="this.$store.state.pageset.pcshow" />
      <img :src="elmentList[0].phoneImg" v-else />
      <div class="img-con">
        <div class="title">
          <h1>{{ elmentList[0].descList[0].descName }}</h1>
          <div class="title-box">
            <div class="title-series">轻享系列</div>
          </div>
        </div>
      </div>
      <div class="dec" v-animate="'queue-bottom'">
        <!-- <div class="dec-par">
          <div class="dec-par-item" v-for="index in 4" :key="index">
            <p class="dec-par-item-title">
              {{ elmentList[0].descList[index].descName }}
            </p>
            <p class="dec-par-item-dec">
              {{ elmentList[0].descList[index + 4].descName }}
            </p>
          </div>
        </div> -->
        <div>
          <el-button
            size="small"
            style="margin-right: 20px"
            class="btn-div-experience btn-div-order"
            plain
            @click="retention"
            >立即订购</el-button
          >
          <el-button
            size="small"
            class="btn-div-experience btn-div-test"
            plain
            @click="retention"
            >预约体验</el-button
          >
        </div>
        <div class="scroll-wrap">
          <div class="remark">
            本页面视频中出现的产品仅供参考，产品的外观、配置、型号等细节可能有所差异，具体请以当地实际交付为准。
          </div>
        </div>
      </div>
    </div>
    <div class="electricWheel-content">
      <div class="content-one" :id="'elm' + elmentList[1].id">
        <img
          :src="elmentList[1].pcImg"
          v-if="this.$store.state.pageset.pcshow"
        />
        <img :src="elmentList[1].phoneImg" v-else />
        <div class="dec" v-animate="'queue-bottom'">
          <div class="dec-left">
            <div class="dec-left-desc" v-if="this.$store.state.pageset.pcshow">
              <span class="p1">{{ elmentList[1].descList[0].descName }}</span>
              <div class="title-series">轻享系列</div>
            </div>
            <div class="dec-left-desc" v-else>
              <div class="title-series">轻享系列</div>
              <span class="p1">{{ elmentList[1].descList[0].descName }}</span>
            </div>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience white-btn"
              plain
              @click="retention"
              >立即订购</el-button
            >
          </div>
          <div class="dec-right">
            <p class="p2">
              {{ elmentList[1].descList[1].descName }}
            </p>
          </div>
          <div class="phone-order" v-if="!this.$store.state.pageset.pcshow">
            <el-button
              size="small"
              class="btn-div-experience white-btn"
              plain
              @click="retention"
              >立即订购</el-button
            >
          </div>
        </div>
      </div>
      <div class="content-two" :id="'elm' + elmentList[2].id">
        <img
          :src="elmentList[2].pcImg"
          v-if="this.$store.state.pageset.pcshow"
        />
        <img :src="elmentList[2].phoneImg" v-else />
        <div class="dec">
          <div class="dec-left">
            <p class="p1">{{ elmentList[2].descList[0].descName }}</p>
            <p class="p2">{{ elmentList[2].descList[1].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >立即订购</el-button
            >
          </div>
          <div class="dec-right"></div>
          <el-button
            v-if="!this.$store.state.pageset.pcshow"
            size="small"
            class="btn-div-experience black-btn"
            plain
            @click="retention"
            >立即订购</el-button
          >
        </div>
      </div>
      <!-- 左右排列参数 -->
      <div class="content-tree" v-if="this.$store.state.pageset.pcshow">
        <div
          v-for="index in 4"
          :key="index"
          :id="'elm' + elmentList[index + 2].id"
        >
          <div class="fun-item" v-if="index % 2 === 0">
            <div class="fun-left">
              <img :src="elmentList[index + 2].pcImg" />
            </div>
            <div class="fun-right">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">
                  {{ elmentList[index + 2].descList[0].descName }}
                </p>
                <p class="p2">
                  {{ elmentList[index + 2].descList[1].descName }}
                </p>
              </div>
            </div>
          </div>
          <div class="fun-item" v-else>
            <div class="fun-left">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">
                  {{ elmentList[index + 2].descList[0].descName }}
                </p>
                <p class="p2">
                  {{ elmentList[index + 2].descList[1].descName }}
                </p>
              </div>
            </div>
            <div class="fun-right">
              <img :src="elmentList[index + 2].pcImg" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-tree" v-else>
        <div v-for="index in 4" :key="index">
          <div>
            <img
              :src="elmentList[index + 2].phoneImg"
              width="100%"
              height="100%"
            />
          </div>
          <div class="fun-dec">
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 2].descList[0].descName }}</p>
              <p class="p2">{{ elmentList[index + 2].descList[1].descName }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品参数 -->
      <div class="content-par-remark" :id="'elm' + elmentList[7].id">
        <div v-if="this.$store.state.pageset.pcshow">
          <p class="remark-title">规格参数</p>
          <table class="content-par-table" border="1" cellspacing="0">
            <tr>
              <td class="table-title title-left-one left-one-border">
                {{ elmentList[7].descList[0].descName }}
              </td>
              <td class="table-title title-right">
                {{ elmentList[8].descList[0].descName }}
              </td>
              <td class="table-title title-right">
                {{ elmentList[9].descList[0].descName }}
              </td>
              <td class="table-title title-right title-right-fwo">
                {{ elmentList[10].descList[0].descName }}
              </td>
            </tr>
            <tr v-for="index in 11" :key="index">
              <td
                class="table-content title-left-one"
                :class="index == 11 ? 'left-one-bottm-border' : ''"
              >
                {{ elmentList[7].descList[index].descName }}
              </td>
              <td class="table-content">
                {{ elmentList[8].descList[index].descName }}
              </td>
              <td class="table-content">
                {{ elmentList[9].descList[index].descName }}
              </td>
              <td
                class="table-content"
                :class="index == 11 ? 'left-fwo-bottm-border' : ''"
              >
                {{ elmentList[10].descList[index].descName }}
              </td>
            </tr>
          </table>
          <el-button
            v-animate="'queue-bottom'"
            size="small"
            class="btn-div-experience white-btn"
            plain
            @click="openPdf"
            >产品手册</el-button
          >
        </div>
        <div v-else>
          <img :src="elmentList[7].phoneImg" />
          <table
            v-for="indexT in 3"
            :key="indexT"
            class="content-par-table"
            border="1"
            cellspacing="0"
          >
            <tr>
              <td class="title-left-one">
                {{ elmentList[7].descList[0].descName }}
              </td>
              <td>
                {{ elmentList[7 + indexT].descList[0].descName }}
              </td>
            </tr>
            <tr v-for="index in 11" :key="index">
              <td class="title-left-one">
                {{ elmentList[7].descList[index].descName }}
              </td>
              <td>
                {{ elmentList[7 + indexT].descList[index].descName }}
              </td>
            </tr>
          </table>
          <el-button
            v-animate="'queue-bottom'"
            size="small"
            class="btn-div-experience white-btn"
            plain
            @click="openPdf"
            >产品手册</el-button
          >
        </div>  
      </div>

      <div class="content-par-order" :id="'elm' + elmentList[10].id">
        <div class="img" v-if="!$store.state.pageset.pcshow">
          <img
            :src="elmentList[11].phoneImg"
            style="width: 327px; height: 327px"
          />
        </div>
        <div class="dec" v-animate="'queue-bottom'">
          <div class="title"  v-if="$store.state.pageset.pcshow">
            <span class="title-name">{{
              elmentList[11].descList[0].descName
            }}</span>
            <div class="title-box">
              <div class="title-series">轻享系列</div>
            </div>
          </div>
          <div class="title"  v-else>
            <div class="title-box">
              <div class="title-series">轻享系列</div>
            </div>
            <span class="title-name">{{
              elmentList[11].descList[0].descName
            }}</span>
           
          </div>
          <p>{{ elmentList[11].descList[1].descName }}</p>
          <div>
            <el-button
              size="small"
              style="background-color: black; color: #fff"
              class="btn-div-experience"
              plain
              @click="retention"
              >立即订购</el-button
            >
            <el-button
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >预约体验</el-button
            >
          </div>
        </div>
        <div class="img" v-if="this.$store.state.pageset.pcshow">
          <img
            :src="elmentList[11].pcImg"
            style="width: 650px; height: 650px"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import chemicalWheelLightly from "./chemicalWheelLightly.js";

export default chemicalWheelLightly;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "chemicalWheelLightly.scss";
@import "../common.scss";
</style>
